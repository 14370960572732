@tailwind base;
@tailwind components;
@tailwind utilities;



@layer components {

  .anchor-cont {
    @apply flex items-center p-2 text-gray-300 rounded-md hover:text-white hover:bg-blue-900;
  }

  .anchor-txt {
    @apply flex-1 ml-2 font-light hover:font-medium whitespace-nowrap antialiased;
  }


}
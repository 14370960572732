@media screen and (max-width: 768px) {
  .LeftSidebar {
    position: fixed;
    z-index: 999;
    width: min(250px, 100%);
    left: 0;
    top: 0;
    bottom: 0;
    height: 200vh;
    border-radius: 10px;
  }

  .greet {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .search {
    margin-top: 15px;
    margin-left: 2.5rem;
  }
  .mwi {
    width: 280px;
    gap: 2rem;
  }

  .mid {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
  }

  .cha {
    display: flex;
    /* justify-content: top; */
    align-items: start;
  }

  .prod {
    border-radius: 10px;
  }
  .Tcha {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .cust {
    width: 280px;
  }

  .groupover {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  .menu {
    padding-left: 10px;
  }

  #sidebar {
    display: none;
  }

}

.App {
  background-color: rgb(240, 238, 232);
}

.responsive {
  display: inline !important;
  position: relative;
  z-index: 12 !important;
}

/* Table Styling */

table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table tr {
  background-color: #ffffff;
  /* border: 1px solid #ddd; */
}

table th,
table td {
  padding: 15px;
  text-align: start;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    display: block;
  }

  table td {
    display: block;
    text-align: start;
  }

  .tdtxt {
    text-align: end;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }

  .pic {
    margin-left: 14px;
  }
}

/* circle CSS */

.s {
  position: absolute;
  inset: 50%;
  background: white;
  width: 15px;
  height: 15px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.concentric-ring {
  width: 179px;
  height: 179px;
  border: 25px solid #dcd9de; /* Outer ring */
  border-radius: 50%; /* Makes it a circle */
  margin-top: 25px;
}

.outer {
  width: 200px;
  height: 200px;
  background: conic-gradient(rgb(99, 8, 211) 65%, rgba(207, 206, 213, 0) 0%);
}

.inner {
  width: 120px;
  height: 120px;
  background: #ffffff;
}

.circle {
  width: 205px;
  height: 205px;
  background: conic-gradient(
    transparent 0deg,
    #f418a4 0 0.2turn,
    transparent 0.12turn
  );
  border-radius: 50%;
}

.inner2 {
  width: 110px;
  height: 110px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.number {
  font-size: 25px;
  font-family: sans-serif;
  margin: 0;
}
.sp {
  font-family: sans-serif;
  font-size: 10px;
  margin: -10px;
}
.sc {
  font-family: sans-serif;
  font-size: 10px;
  margin: 1.8px;
}
